import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import { THEME, abbreviateNumber, storyLink } from 'utils'
import AvatarIcon from 'icons/Avatar'
import LiveNow from 'icons/LiveNow'
import VideoPlayerLink from 'components/VideoPlayerLink'
import TimeDependent from 'components/TimeDependent'
import Countdown from 'components/Countdown'

const LiveVideo = ({ story }) => {
  return (
    <>
      <section className='liveVideo'>
        <div className='liveVideo__video'>
          <VideoPlayerLink story={story} />
        </div>
        <div className='liveVideo__detail'>
          <div className='liveVideo__labels'>
            <div className='liveVideo__label liveVideo__label--red'>
              <LiveNow />
              <span>
                <TimeDependent
                  date={story.post_type_meta.live.datetime}
                  renderBefore={() => {
                    if (story.post_type_meta.live.is_live) {
                      return 'Live'
                    } else {
                      return (
                        <Countdown
                          datetime={story.post_type_meta.live.datetime}
                          timeoutText='Starting Soon'
                          countdownText='Starts In'
                        />
                      )
                    }
                  }}
                  renderAfter={() => 'Live'}
                />
              </span>
            </div>
            <div className='liveVideo__label liveVideo__label--white'>
              <AvatarIcon />
              <span>{abbreviateNumber(story?.post_type_meta?.live?.viewers?.count ?? 1)}</span>
            </div>
          </div>
          <h1 className='liveVideo__title'>{story.title}</h1>
          <Link href={storyLink(story).href} as={storyLink(story).as} legacyBehavior>
            <a className='btn btn--primary'>Watch Now</a>
          </Link>
        </div>
      </section>
      <style jsx>{`
        .liveVideo {
          display: flex;
          flex: 0 1 auto;
          flex-direction: row;
          flex-wrap: wrap;
          background-color: ${THEME.COLORS.SECONDARY};
          margin: 0 0 1rem;
        }
        .liveVideo__video {
          flex-basis: 100%;
          max-width: 100%;
          padding: 0;
        }
        .liveVideo__detail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-basis: 100%;
          max-width: 100%;
          padding-right: 0.75rem;
          padding-left: 0.75rem;
        }
        .liveVideo__title {
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 1.25rem;
          font-weight: 700;
          color: white;
          margin: 0;
          padding: 1rem 0 0;
          text-align: center;
        }
        .liveVideo__labels {
          display: none;
          justify-content: center;
        }
        .liveVideo__label {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 1.25rem;
          font-weight: 700;
          text-transform: uppercase;
          background: white;
          color: ${THEME.COLORS.SECONDARY};
          display: flex;
          height: auto;
          padding: 0.5rem 1rem;
          align-items: center;
          justify-content: center;
        }
        .liveVideo__label--red {
          background-color: ${THEME.COLORS.PRIMARY};
          color: #fff;
          margin-right: 2px;
        }
        .btn {
          max-width: 320px;
          width: 100%;
          margin: 1rem auto;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .liveVideo__title {
            padding: 2rem 0 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .liveVideo__video {
            flex-basis: 58.33333%;
            max-width: 58.33333%;
          }
          .liveVideo__detail {
            flex-basis: 41.66667%;
            max-width: 41.66667%;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
          }
          .liveVideo__title {
            font-size: 1.875rem;
            padding: 2rem 0;
          }
          .liveVideo__labels {
            display: flex;
          }
        }
      `}</style>
    </>
  )
}

export default observer(LiveVideo)
