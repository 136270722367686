import React from 'react'
import { THEME } from 'utils'

export default function PlayIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 14.68 19.92' className='svgIcon iconPlay' style={{ width, height, fill: color }}>
      <title>Play Video</title>
      <polygon points='0 0 14.69 9.96 0 19.92 0 0' />
    </svg>
  )
}
