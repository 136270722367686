import React, { useState } from 'react'
import { useInterval } from 'hooks'

const Countdown = ({ datetime, timeoutText, countdownText }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(datetime) - +new Date()
    if (difference > 0) {
      return {
        minutes: Math.floor((difference / 1000 / 60) % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }),
        seconds: Math.floor((difference / 1000) % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      }
    }
    return false
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useInterval(() => {
    setTimeLeft(calculateTimeLeft())
  }, 250)

  return (
    <span className='countdown'>
      {timeLeft ? `${countdownText} ${timeLeft.minutes}:${timeLeft.seconds}` : timeoutText}
    </span>
  )
}

export default Countdown
