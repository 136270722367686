import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import LiveNow from 'icons/LiveNow'
import Play from 'icons/Play'
import NextArrow from 'icons/NextArrow'
import { abbreviateNumber, THEME } from 'utils'
import FeaturedVideo from 'components/FeaturedVideo'
import LiveVideo from 'components/LiveVideo'
import TimeDependent from 'components/TimeDependent'
import Countdown from 'components/Countdown'

const PreState = ({ item }) => {
  if (item.post_type_meta.live.is_live) {
    return <LiveState item={item} />
  } else {
    return (
      <div className='liveVideos__itemOverlay liveVideos__itemOverlay--pre'>
        <div className='indicator'>
          <LiveNow />
          <Countdown
            datetime={item.post_type_meta.live.datetime}
            timeoutText='Starting Soon'
            countdownText='Starts In'
          />
        </div>
      </div>
    )
  }
}
const LiveState = ({ item }) => (
  <div className='liveVideos__itemOverlay liveVideos__itemOverlay--live'>
    <div className='indicator'>
      <LiveNow />
      <span>Live</span>
    </div>
    <div className='controls'>
      <div className='play'>
        <Play color='#fff' />
      </div>
      <div className='viewers'>{abbreviateNumber(item?.post_type_meta?.live?.viewers?.count ?? 1)} viewers</div>
    </div>
  </div>
)
const LiveVideos = ({ items, videosPage = false }) => {
  const container = useRef(null)
  let [scrollLeft, setScrollLeft] = useState(0)
  let [showNextArrow, setShowNextArrow] = useState(items.length > 2)
  if (!items.length) return null
  const handleScroll = () => {
    setScrollLeft(container.current.scrollLeft)
    setShowNextArrow(container.current.scrollLeft !== container.current.scrollWidth - container.current.clientWidth)
  }
  const scroll = (direction) => {
    let scrollInterval
    const initial = scrollLeft
    if (direction === 'left') {
      scrollInterval = setInterval(() => {
        if (container.current.scrollLeft === 0 || container.current.scrollLeft === initial - 600)
          clearInterval(scrollInterval)
        container.current.scrollLeft -= 20
      }, 1)
    } else {
      scrollInterval = setInterval(() => {
        if (
          container.current.scrollLeft >= container.current.scrollWidth - container.current.clientWidth ||
          container.current.scrollLeft === initial + 600
        )
          clearInterval(scrollInterval)
        container.current.scrollLeft += 20
      }, 1)
    }
  }
  if (items.length === 1) {
    return videosPage ? <FeaturedVideo items={items} videosPage={videosPage} live /> : <LiveVideo story={items[0]} />
  }
  return (
    <>
      <div className={`liveVideos liveVideos--${items.length}`}>
        {scrollLeft > 0 ? (
          <a className='liveVideos__control liveVideos__control--prev' onClick={() => scroll('left')}>
            <NextArrow />
          </a>
        ) : null}
        <div ref={container} className='row row--scroll' onScroll={handleScroll}>
          {items.map((item) => {
            let image =
              item.post_type_meta.barstool_original && item.post_type_meta.barstool_original.image
                ? item.post_type_meta.barstool_original.image
                : item.thumbnail.raw_featured
            const imageUrl = new URL(image)
            if (!imageUrl.searchParams.get('crop')) {
              imageUrl.searchParams.set('crop', '16:9')
            }
            const imageSrc = imageUrl.toString()
            return (
              <div className='col col-xs' key={item.id}>
                <Link href='/live/[id]/[slug]' as={`/live/${item.id}/${item.slug}`} legacyBehavior>
                  <a className='liveVideos__item'>
                    <div className='liveVideos__itemImage'>
                      <TimeDependent
                        date={item.post_type_meta.live.datetime}
                        renderBefore={() => <PreState item={item} />}
                        renderAfter={() => <LiveState item={item} />}
                      />
                      <img src={imageSrc} alt={item.title} />
                    </div>
                    <h2 className='liveVideos__itemTitle'>{item.title}</h2>
                  </a>
                </Link>
              </div>
            )
          })}
        </div>
        {showNextArrow ? (
          <a className='liveVideos__control liveVideos__control--next' onClick={() => scroll('right')}>
            <NextArrow />
          </a>
        ) : null}
      </div>
      <style jsx>{`
        .liveVideos {
          background: ${videosPage ? 'transparent' : 'white'};
          padding: 1rem;
          padding-bottom: 0;
          box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
          margin-bottom: 1rem;
          position: relative;
        }
        .liveVideos__control {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          min-width: 44px;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background-color: white;
          z-index: 1;
          cursor: pointer;
        }
        .liveVideos__control--prev {
          left: 15px;
        }
        .liveVideos__control--next {
          right: 15px;
        }
        .liveVideos .row--scroll {
          padding-bottom: 1.5rem;
        }
        .liveVideos .col-xs {
          min-width: 315px;
          flex-basis: 315px;
        }
        .liveVideos__item {
          display: block;
        }
        .liveVideos__itemImage {
          position: relative;
          margin-bottom: 1rem;
          overflow: hidden;
        }
        .liveVideos__itemImage img {
          display: block;
          width: 100%;
        }
        .liveVideos__itemTitle {
          margin: 0;
          color: ${videosPage ? '#fff' : THEME.COLORS.TEXTCOLOR};
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.1px;
          line-height: 20px;
          text-transform: none;
        }
        @media (min-width: 47.9375rem) {
          .liveVideos .col-xs {
            min-width: 480px;
            flex-basis: 480px;
          }
        }
        @media (min-width: 47.9375rem) {
          .liveVideos {
            margin-bottom: 0;
          }
        }
        .liveVideos--1 {
          margin-bottom: 1rem;
        }
        .liveVideos--1 .col-xs {
          min-width: 100%;
          flex-basis: 100%;
        }
        .liveVideos--2 {
          margin-bottom: 1rem;
        }
        .liveVideos--2 .col-xs {
          min-width: 315px;
          flex-basis: 315px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .liveVideos--2 .col-xs {
            min-width: 50%;
            flex-basis: 50%;
          }
          .liveVideos__itemTitle {
            font-size: 24px;
            line-height: 29px;
          }
        }
      `}</style>
    </>
  )
}

export default observer(LiveVideos)
